@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'body-font';
  src: local('body-font'), url(./fonts/roboto-v30-latin-regular.woff) format('woff');
}

@font-face {
  font-family: 'heading-font';
  src: local('heading-font'), url(./fonts/RobotoMono-Medium.ttf) format('ttf');
}

body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  color: black;
  margin: 0;
  font-family: "body-font";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "body-font";
  font-size: 32px;
  font-weight: bold;
}

h2 {
  font-family: "body-font";
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 767px) {
  h2 {
    font-weight: 500;
  }
}

/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */