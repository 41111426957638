:root {
  --primary-color: #14A76C;
  --secundary-color: #F5F5F5;
  --primary-bg-color: #14a76c5b;
  --footer-height: 65px;
}

.App {
  width: 100%;
  height: 100%;
}

.AppContainer {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {

  .footer-web-links,
  .AppContainer {
    width: 70%;
  }

  .small-canvas {
    width: 210px !important;
    height: 350px !important;
  }
}

@media (max-width: 767px) {

  footer {
    position: fixed !important;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
}

.gm-style-iw-a:has(.gm-style-iw-d div) {
  display: flex;
}

.gm-style-iw-a {
  display: none;
}

.gm-style-iw button {
  display: none !important;
}

.scanner-qrcode svg path {
  stroke: #A8FF35;
}

/* 
@media (min-width: 768px) and (max-width: 1023px) {
  .App {
    width: 600px;
    padding: 2rem 5rem;
  }

  .small-canvas {
    width: 210px !important;
    height: 350px !important;
  }
}

@media(min-width: 1024px) {
  .App {
    max-width: 1000px;
    padding: 5rem 10rem !important;
  }

  .small-canvas {
    width: 210px !important;
    height: 350px !important;
  }
} */

.AppScreen {
  top: 0;
  bottom: 0;
  right: 0;
}

body:has(.login-page) .AppScreen {
  height: 100% !important;
  left: 0 !important;
}

body:has(.page-no-footer) .AppScreen {
  height: 100% !important;
}

@media (max-width: 767px) {
  body:has(.login-page) .AppScreen {
    height: calc(100% - 88px) !important;
  }
}

body:has(.page-footer-links) .footer-buttons,
body:has(.page-footer-links) .menuBar-icon,
body:has(.page-no-footer) .footer-links,
body:has(.page-no-footer) .footer-buttons,
body:has(.page-no-footer) .menuBar-icon,
body:has(.page-footer-buttons) .footer-links,
body:has(.page-footer-buttons) .header-button-close,
body:has(home) .header-button-close {
  display: none;
}

body:has(.page-header-settings) .header-button-settings,
body:has(.page-header-close) .header-button-close {
  display: flex;
}

@media (min-width: 768px) {

  body:has(.close-menu) .AppScreen {
    left: 55px;
  }

  body:has(.open-menu) .AppScreen {
    left: 190px;
  }

  body:has(.page-no-footer) .footer-buttons,
  body:has(.page-no-footer) .menuBar-icon {
    display: flex;
  }

  body:has(.page-header-settings) .header-button-settings,
  body:has(.page-header-close) .header-button-close {
    display: none;
  }
}

body:has(.page-no-header) .header-navlinks,
body:has(.page-no-header) .header-button-close,
body:has(home) .header-navlinks,
.header-navlinks span:last-child div.dot {
  display: none;
}

.header-navlinks a:hover {
  text-decoration: underline;
}

.header-navlinks span:last-child a:hover {
  text-decoration: none !important;
}

.header-navlinks span.navlink,
.header-navlinks div.dot,
.header-navlinks span:last-child .navlink {
  color: rgb(145, 158, 171);
  pointer-events: none;
}

.button {
  padding: .225rem 2rem;
  border: 1px solid transparent;
  border-radius: 50px;
  text-align: center;
}

button:disabled {
  opacity: 0.6;
}

.primary-button {
  background-color: var(--primary-color);
  color: black;
}

.secundary-button {
  background-color: black;
  color: white;
}

.text-button {
  font-weight: 600;
  border-radius: 10px !important;
}

.extra-button {
  background-color: var(--secundary-color);
  color: black;
  border: 1px solid transparent;
}

.primary-button:hover {
  background-color: var(--primary-bg-color);
}

.secundary-button:hover {
  background-color: rgb(69, 79, 91);
}

.text-button:hover {
  background-color: var(--primary-bg-color);
}

.extra-button:hover {
  border: 1px solid var(--primary-color);
}

.toogle-buttons {
  background-color: var(--secundary-color);
  border: 1px solid black;
  border-radius: 50px;
}

.card {
  background-color: white;
  padding: 1rem;
  border-radius: 1.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.toogle-buttons .primary-button {
  margin: -1px;
}

select,
input[type='number'],
input[type='email'],
input[type='text'],
input[type='password'],
.input-checkbox {
  background-color: var(--secundary-color);
  padding: .25rem .75rem !important;
  border: 1px solid transparent;
  border-radius: 50px;
  color: black !important;
  outline: transparent;
}

select:has(option:checked[hidden]) {
  opacity: 1;
  color: #9ca3af !important;
}

select option {
  color: black;
}

.input-checkbox label {
  color: black;
}

.input-checkbox .checkbox,
.input-simple-checkbox .checkbox,
.input-radio {
  accent-color: var(--primary-color);
}

.small-canvas {
  width: 210px !important;
  height: 300px !important;
}

.scrollbar-small::-webkit-scrollbar {
  width: .5rem;
}

/* Track */
.scrollbar-small::-webkit-scrollbar-track {
  background: var(--secundary-color);
}

/* Handle */
.scrollbar-small::-webkit-scrollbar-thumb {
  background: var(--primary-bg-color);
  border-radius: .5rem;
}

/* Handle on hover */
.scrollbar-small::-webkit-scrollbar-thumb:hover {
  --tw-text-opacity: 1;
  background: rgb(156, 163, 175);
}

table>tbody>tr>td {
  padding: .25rem;
}

.map-container {
  height: 300px;
}

.calendly-inline-widget {
  width: 100%;
  height: 100% !important;
  position: absolute;
}